import { formatCurrency } from '../../utils/currency';

export class PaymentUtils {
  static FormatCouponOff(coupon: { percentOff: number; amountOff: number }) {
    if (coupon.amountOff > 0) {
      return `${formatCurrency(coupon.amountOff / 100)} off`;
    }
    return `${coupon.percentOff}% off`;
  }

  static CalculateDiscountedPrice(
    amount: number,
    coupon: { percentOff: number; amountOff: number } | null | undefined
  ) {
    if (!coupon) return amount;
    if (coupon.amountOff > 0) {
      return Math.max(amount - coupon.amountOff / 100, 0);
    }
    return amount * (1 - coupon.percentOff / 100);
  }

  static ParseHeadcount(value?: string | null) {
    if (!value) return 0;
    return parseInt(value);
  }
}
